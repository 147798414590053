

.sp3 {
    height: calc(60 / 1280 * 100vh);
}

.p4 {
    width: calc(338 / 720 * 100vw);
    height: calc(84 / 1280 * 100vh);
}

.sp4 {
    height: calc(95 / 1280 * 100vh);
}

.sp5 {
    height: calc(71/1280*100vh);
}

.p5 {
    width: calc(34 / 720 * 100vw);
    height: calc(34 / 1280 * 100vh);
    margin: 0 auto;
}

.p6 {
    width: calc(550 / 720 * 100vw);
    height: calc(474 / 1280 * 100vh);
    position: absolute;
}
