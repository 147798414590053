
.page-1 {
}

.page-2 {
}

.page-2-bg2 {
    position: absolute;
    top: 0;
    right: 0;
}

.sp0 {
    height: calc(100 / 720 * 100vw);
    max-height: 100px;
}


.b1 {
    width: calc(107 / 720 * 100vw);
    height: calc(107 / 1280 * 100vh);
    max-width: 107px;
    max-height: 107px;
    position: absolute;
    top: calc(323 / 1280 * 100vh);
    left: calc(40 / 720 * 100vw);
}

.b2 {
    width: calc(140 / 720 * 100vw);
    height: calc(72 / 1280 * 100vh);
    position: absolute;
    top: calc(850 / 1280 * 100vh);
    right: calc(30 / 720 * 100vw);
}

.p1 {
    width: 89vw;
    max-width: 720px;
    /*height: 5vw;*/
}

.sp1 {
    height: calc(22 / 1280 * 100vh);
}

.p2 {
    width: calc(540 / 720 * 100vw);
    height: calc(21 / 1280 * 100vh);

    max-width: 551px;
    max-height: 32px;
}

.sp2 {
    height: calc(64 / 1280 * 100vh);
}

.p3 {
    width: calc(471 / 720 * 100vw);
    height: calc(673 / 1280 * 100vh);
}

.p7 {
    /*width: calc(468 / 720 * 100vw);*/
    height: calc(733 / 1280 * 100vh);
    /*max-width: 468px;*/
    max-height: 733px;
    margin-top: calc(100 / 1280 * 100vh);
    /* position: absolute; */
    /* top: calc(68 / 1280 * 100vh); */
}

.p8 {
    width: calc(274 / 720 * 100vw);
    height: calc(271 / 1280 * 100vh);
    max-width: 274px;
    max-height: 271px;
    position: absolute;
    left: calc(-50 / 1280 * 100vw);
    top: calc(400 / 1280 * 100vh);
}

.p9 {
    width: calc(274 / 720 * 100vw);
    height: calc(271 / 1280 * 100vh);
    max-width: 274px;
    max-height: 271px;
    position: absolute;
    right: calc(-76 / 1280 * 100vw);
    top: calc(370 / 1280 * 100vh);
}

.p10 {
    width: calc(305 / 720 * 100vw);
    height: calc(431 / 1280 * 100vh);
    max-width: 305px;
    max-height: 431px;
    position: absolute;
    left: calc(1 / 1280 * 100vw);
    top: calc(80 / 1280 * 100vh);
}


.p11 {
    width: calc(305 / 720 * 100vw);
    height: calc(431 / 1280 * 100vh);
    max-width: 305px;
    max-height: 431px;
    position: absolute;
    right: calc(1 / 1280 * 100vw);
    top: calc(138 / 1280 * 100vh);
}


.p12 {
    width: calc(433 / 720 * 100vw);
    height: calc(450 / 1280 * 100vh);
    max-width: 433px;
    max-height: 450px;
    position: absolute;
    right: calc(40 / 1280 * 100vw);
    top: calc(364 / 1280 * 100vh);
}

.box-p3 {

    width: calc(523 / 720 * 100vw);
    max-width: 526px;
    position: relative;
}