.swiper-layout {
    background-size: cover;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

}

.page-1 {
    height: 100%;
}

.page-home {
    height: 100%;
    background: url("./bg1.png") no-repeat;
    background-size: cover;
    background-position: center;;
}

.home-1 {
    width: 100%;
    height: calc(102 / 1280 * 100vh);
    background: rgba(0, 0, 0, 0.56);
    display: flex;
    flex-direction: row;
    justify-content: space-between; /* 垂直方向两端对齐 */
    align-items: center; /* 水平方向居中 */
    padding-left: calc(21 / 720 * 100vw);
    padding-right: calc(21 / 720 * 100vw);
}

.home-title {
    width: calc(112 / 720 * 100vw);
    max-width: 112px;
}

.home-telegram {
    width: calc(210 / 720 * 100vw);
    max-width: 210px;
}

.home-box-1 {
    height: calc(500 / 1280 * 100vh);
    max-height: 500px;
}

.home-box-2 {
    height: calc(100 / 1280 * 100vh);
    max-height: 100px;
}

.home-2 {
    width: calc(600 / 720 * 100vw);
    height: calc(156 / 1280 * 100vh);
    max-width: 600px;
    max-height: 156px;
    margin: 0 auto;
}

.home-3 {
    width: calc(500/720*100vh);
    height: calc(66 / 1280 * 100vh);

    width: 100%;
    display: flex;
    justify-content: center;
}

