@tailwind base;
@tailwind components;
@tailwind utilities;

#root {
    height: 100%;
}

html,
body {
    position: relative;
    height: 100%;
}


body {
    background: url("BG.png") no-repeat;
    background-size: cover;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 1vw;
    margin: 0 auto;
    padding: 0;
    width: clamp(100vw, 100vw, 720px);;
}

.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
}
